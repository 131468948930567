import { MenuDropdown, Progress, Link } from '@component-library/helios'
import { captureMessage } from '@sentry/react'
import { useEffect, type FC, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePostActionMenus } from '../../api/hooks/usePostActionMenus'
import { useAuth } from '../../hooks/useAuth'
import { trackEvent } from '../../utils/analytics'
import { deepLinktoApplication } from '../../utils/formActions'
import {
  getConfigForGenericDentalIdCardUrl,
  getConfigForHealthNavigatorUrl,
  getConfigForMaxwellHealthUrl,
  getConfigForSLCUrl,
} from '../../utils/getClientConfig'
import type { ValueOf } from '../../utils/helper'
import { ActionMenuError } from '../Error/ActionMenuError'

import styles from './SponsorActionsMenu.module.css'

export type SponsorActionsMenuProps = {
  type: 'button' | 'icon'
  menuItemClickTrackTitle: string
  showViewSponsor?: boolean
  sponsorId: number
  benefits: string[]
  dentalPolicy?: string
  policyNumbers: string[]
  hasStopLoss?: boolean
  hasHealthNavigator?: boolean
  hasDentalBenefit?: boolean
  activeSponsor?: boolean
}

// Expected error messages received from sponsors action menu
const ACTION_MENU_ERRORS = {
  NONE: '',
  WEB_ADMIN_NOT_AVAILABLE: 'Web admin is not available',
  BROKER_DELETED: 'broker deleted',
} as const

export const SponsorActionsMenu: FC<SponsorActionsMenuProps> = ({
  type,
  menuItemClickTrackTitle,
  showViewSponsor = false,
  sponsorId,
  benefits,
  dentalPolicy,
  policyNumbers,
  hasStopLoss,
  hasHealthNavigator,
  hasDentalBenefit,
  activeSponsor = false,
}) => {
  const { user } = useAuth()
  const actionsMenu = usePostActionMenus({
    sponsorId,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    email: user?.email ?? '',
    brokerId: user?.brokerID ?? '',
    policyIds: policyNumbers.map((policyNumber) => parseInt(policyNumber, 10)),
  })

  const dentalIdCardUrl = getConfigForGenericDentalIdCardUrl()
  const healthNavigatouUrl = getConfigForHealthNavigatorUrl()
  const maxwellHealthUrl = getConfigForMaxwellHealthUrl()

  const hasMaxwellHealthServices = benefits?.filter((ben) => ben.toLowerCase() === 'maxwell health').length

  const onToggleActionMenu = () => {
    if (user?.permissions.isInternalUser) return
    actionsMenu.mutate()
  }

  const onMenuDropdownToggle = (nextShow: boolean) => {
    if (nextShow) {
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: menuItemClickTrackTitle,
      })
    }
  }

  const slcURL = getConfigForSLCUrl()

  const getMenuItemClickTracker = (key: string) => {
    return function trackMenuItemClick() {
      const eventName = `${menuItemClickTrackTitle}:${key}`
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: eventName,
      })
    }
  }

  const getExternalURLFromAccessItem = (item: SLCActionTypes): string => {
    if (item === 'IDCARD') return `${dentalIdCardUrl}=${dentalPolicy}`
    else if (item === 'MAXWELLHEALTH') return maxwellHealthUrl
    else return healthNavigatouUrl
  }

  const redirectToSlconnect = (url: string, slcFunction: string) => {
    const elements: InputElement[] = [
      {
        elementId: 'brokerParticipantId',
        elementName: 'brokerParticipantId',
        elementValue: user?.brokerID ?? '',
      },
      {
        elementId: 'brokerUsername',
        elementName: 'brokerUsername',
        elementValue: user?.username ?? '',
      },
      {
        elementId: 'sponsorId',
        elementName: 'sponsorId',
        elementValue: sponsorId.toString(),
      },
    ]
    deepLinktoApplication(url, slcFunction, elements, 'slconnect')
  }

  const getListOfAvailableFunctions = (accessItems: SLCActionTypes[] | undefined) => {
    const availableAccessItems =
      accessItems
        ?.map((accessItem) => {
          const itemData = SLCActionTypesData[accessItem]
          const trackMenuItemClick = getMenuItemClickTracker(itemData.key)
          const showBillsPremium = hasStopLoss || hasHealthNavigator

          if (itemData.key === 'bills' && showBillsPremium) {
            itemData.label = 'Bills/Premium'
          }

          return {
            ...itemData,
            onClick: () => {
              trackMenuItemClick()
              if (accessItem === 'IDCARD' || accessItem === 'HEALTHNAVIGATOR' || accessItem === 'MAXWELLHEALTH') {
                window.open(getExternalURLFromAccessItem(accessItem), '_blank')
              } else {
                redirectToSlconnect(`${slcURL}brokerexpsso/brokerexpsso.cfm`, accessItem)
              }
            },
          }
        })
        .sort((item1, item2) => item1.sortOrder - item2.sortOrder) ?? []
    return availableAccessItems
  }

  useEffect(() => {
    if (
      actionsMenu.data?.actionMenu.errorMessage != null &&
      actionsMenu.data.actionMenu.errorMessage !== ACTION_MENU_ERRORS.NONE
    ) {
      captureMessage(`Sponsor ${sponsorId} action menu error: ${actionsMenu.data.actionMenu.errorMessage}`)
    }
  }, [sponsorId, actionsMenu.data?.actionMenu.errorMessage])

  let slcAccessItems: SLCActionTypes[] = []
  if (actionsMenu.data?.actionMenu.accessItems) {
    slcAccessItems = actionsMenu.data?.actionMenu.accessItems
      .map((item) => SLCActionTypesMap[item])
      .filter((item) => item)

    if (hasDentalBenefit && dentalPolicy) slcAccessItems = [...slcAccessItems, 'IDCARD']
    if (hasHealthNavigator) slcAccessItems = [...slcAccessItems, 'HEALTHNAVIGATOR']
    if (hasMaxwellHealthServices) slcAccessItems = [...slcAccessItems, 'MAXWELLHEALTH']
  }

  const viewClientLink = showViewSponsor ? (
    <ViewClientLink trackClick={getMenuItemClickTracker('view-client')} sponsorId={sponsorId} />
  ) : undefined

  return (
    <>
      <MenuDropdown
        toggleClass={type === 'button' ? styles.dropdownButton : styles.dropdownIcon}
        data={
          user?.permissions.isInternalUser
            ? {
                label: type === 'button' ? 'Actions' : undefined,
                title: viewClientLink,
                error: (
                  <ActionMenuError
                    message={
                      <>
                        The actions menu will not be available until we build broker impersonation in the future.
                        Specific broker permissions can be seen in Sun Life Connect.
                      </>
                    }
                    email="clientservices@sunlife.com"
                    emailDisplayName="Client Services"
                    phone="800-247-6875"
                  />
                ),
              }
            : !actionsMenu.isPending
              ? actionsMenu.data?.actionMenu.errorMessage === ACTION_MENU_ERRORS.NONE
                ? {
                    children: getListOfAvailableFunctions(slcAccessItems),
                    label: type === 'button' ? 'Actions' : undefined,
                    title: slcAccessItems?.length && viewClientLink,
                  }
                : !activeSponsor &&
                    actionsMenu.data?.actionMenu.errorMessage === ACTION_MENU_ERRORS.WEB_ADMIN_NOT_AVAILABLE
                  ? {
                      label: type === 'button' ? 'Actions' : undefined,
                      title: viewClientLink,
                      error: (
                        <ActionMenuError
                          message={
                            <>
                              After 90 days of termination clients no longer have access to Sun Life Connect. If you
                              think this is an error, please contact Client Services.
                            </>
                          }
                          email="clientservices@sunlife.com"
                          emailDisplayName="Client Services"
                          phone="800-247-6875"
                        />
                      ),
                    }
                  : actionsMenu.data?.actionMenu.errorMessage === ACTION_MENU_ERRORS.WEB_ADMIN_NOT_AVAILABLE
                    ? {
                        label: type === 'button' ? 'Actions' : undefined,
                        title: viewClientLink,
                        error: (
                          <ActionMenuError
                            message={
                              <>
                                For more actions, please contact Client Services so we can set you up with Sun Life
                                Connect.
                              </>
                            }
                            email="clientservices@sunlife.com"
                            emailDisplayName="Client Services"
                            phone="800-247-6875"
                          />
                        ),
                      }
                    : actionsMenu.data?.actionMenu.errorMessage === ACTION_MENU_ERRORS.BROKER_DELETED
                      ? {
                          label: type === 'button' ? 'Actions' : undefined,
                          title: viewClientLink,
                          error: (
                            <ActionMenuError
                              message={
                                <>
                                  You don&apos;t have permission to access this client in Sun Life Connect. If you think
                                  this is an error, please contact Client Services.
                                </>
                              }
                              email="clientservices@sunlife.com"
                              emailDisplayName="Client Services"
                              phone="800-247-6875"
                            />
                          ),
                        }
                      : {
                          label: type === 'button' ? 'Actions' : undefined,
                          title: viewClientLink,
                          error: (
                            <ActionMenuError
                              message={
                                <>
                                  There was an error communicating with Sun Life Connect. Please try again in a few
                                  minutes. If this continues, please contact Client Services.
                                </>
                              }
                              email="clientservices@sunlife.com"
                              emailDisplayName="Client Services"
                              phone="800-247-6875"
                            />
                          ),
                        }
              : {
                  label: type === 'button' ? 'Actions' : undefined,
                  children: undefined,
                }
        }
        loadingMessage={
          actionsMenu.isPending && (
            <>
              <div className={styles.paddingOne}>Please stand by</div>
              <Progress id="authProviderLoading" label="Gathering your actions" variant="indeterminate" />
            </>
          )
        }
        displayConfig={{ displayLabelClass: styles.dropdownLabel }}
        dropdownIcon={type === 'button' ? undefined : <i className="far fa-ellipsis-v" aria-hidden="true" />}
        onClick={onToggleActionMenu}
        onToggle={onMenuDropdownToggle}
      />
    </>
  )
}

export const SLCActionTypes = [
  'BILLING',
  'ECLAIMS',
  'EMPLOYEE',
  'EOI',
  'FORMS',
  'EPAY',
  'POLDOCS',
  'SLREPORT',
  'IDCARD',
  'MAXWELLHEALTH',
  'HEALTHNAVIGATOR',
] as const

export type SLCActionTypes = ValueOf<typeof SLCActionTypes>

const SLCActionTypesMap: Record<string, SLCActionTypes> = {
  BILLING: 'BILLING',
  ECLAIMS: 'ECLAIMS',
  EOI: 'EOI',
  EMPLOYEE: 'EMPLOYEE',
  FORMS: 'FORMS',
  EPAY: 'EPAY',
  POLDOCS: 'POLDOCS',
  SLREPORT: 'SLREPORT',
  IDCARD: 'IDCARD',
  MAXWELLHEALTH: 'MAXWELLHEALTH',
  HEALTHNAVIGATOR: 'HEALTHNAVIGATOR',
}

export const SLCActionTypesData: Record<
  SLCActionTypes,
  { key: string; label: string; onClick?: () => void; sortOrder: number; divider?: boolean }
> = {
  BILLING: {
    key: 'bills',
    label: 'Bills',
    sortOrder: 2,
  },
  ECLAIMS: {
    key: 'claims',
    label: 'Claims',
    sortOrder: 3,
  },
  EOI: {
    key: 'eoi',
    label: 'EOI',
    sortOrder: 4,
  },
  EMPLOYEE: {
    key: 'employee',
    label: 'Manage Employees',
    sortOrder: 1,
  },
  FORMS: {
    key: 'forms',
    label: 'Forms',
    sortOrder: 5,
  },
  POLDOCS: {
    key: 'policy-documents',
    label: 'Policy Documents',
    sortOrder: 6,
  },
  SLREPORT: {
    key: 'slreport',
    label: 'Stop Loss ISL Reporting',
    sortOrder: 7,
  },
  HEALTHNAVIGATOR: {
    key: 'health-navigator',
    label: 'Health Navigator Card',
    sortOrder: 8,
  },
  IDCARD: {
    key: 'idcard',
    label: 'Dental ID Card',
    sortOrder: 9,
  },
  EPAY: {
    key: 'payments',
    label: 'Payments',
    sortOrder: 10,
  },
  MAXWELLHEALTH: {
    key: 'maxwell-health',
    label: 'Maxwell Health',
    sortOrder: 11,
  },
}

type ViewClientLinkProps = {
  sponsorId: number
  trackClick: () => void
}

export const ViewClientLink: FC<ViewClientLinkProps> = (props): ReactElement => {
  const navigate = useNavigate()
  const { sponsorId, trackClick } = props
  return (
    <Link
      onClick={() => {
        trackClick()
        navigate(`/sponsor/${sponsorId}`)
      }}
      showIcon
    >
      View client
    </Link>
  )
}
