import { useEffect, useState } from 'react'

import { useGetTerminatedSponsors } from '../../api/hooks/sponsor'
import type { BookOfBusinessSearch } from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import {
  BookOfBusinessActionBar,
  BookOfBusinessTabKey,
} from '../../components/BookOfBusinessActionBar/BookOfBusinessActionBar'
import { BookOfBusinessTable } from '../../components/BookOfBusinessTable/BookOfBusinessTable'
import { QueryResultUnconditional } from '../../components/QueryResultUnconditional/QueryResultUnconditional'
import { useAuth } from '../../hooks/useAuth'
import { useBookOfBusinessPagination } from '../../hooks/useBookOfBusinessPagination'
import { useDebounced } from '../../hooks/useDebounced'
import { trackEvent } from '../../utils/analytics'
import { filterSponsorsBenefits } from '../../utils/benefit'
import { DownloadSponsorsType } from '../../utils/sponsors'

import { useEmptyText } from './useEmptyText'

export const TerminatedSponsors = () => {
  const { sort, pagination, ...paginationCallbacks } = useBookOfBusinessPagination()
  const [search, setSearch] = useState<BookOfBusinessSearch>({ query: '' })
  const debouncedSearch = useDebounced(search, 500)
  const auth = useAuth()
  const enabled = auth.user?.permissions.isInternalUser === false
  const terminatedSponsors = useGetTerminatedSponsors(
    {
      query: {
        q: debouncedSearch.query,
        ...sort,
        ...pagination,
      },
    },
    { enabled, prefetchNextPage: true },
  )
  const emptyText = useEmptyText('terminated', { query: debouncedSearch.query, status: debouncedSearch.status })

  const sponsors = terminatedSponsors.data?.bookOfBusiness?.sponsors ?? []

  const { onPageChange } = paginationCallbacks

  useEffect(
    function resetPageOnSearchChange() {
      if (debouncedSearch.query) onPageChange(1)
    },
    [debouncedSearch, onPageChange],
  )

  // Tracking effects.

  useEffect(() => {
    if (debouncedSearch.query.length > 0 && sponsors.length === 0)
      trackEvent({
        ev_type: 'other',
        ev_action: 'clk',
        ev_title: `book of business table:empty terminated employers query`,
      })
  }, [debouncedSearch, sponsors.length])

  const handleSearchChange = (search: BookOfBusinessSearch) => {
    setSearch(search)
  }

  const handleDownloadClick = () => {
    trackEvent({
      ev_type: 'other',
      ev_action: 'clk',
      ev_title: 'book of business table:link:download terminated employers',
    })
  }

  return (
    <>
      <BookOfBusinessActionBar
        tab={BookOfBusinessTabKey.terminated}
        downloadButtonProps={{
          disabled: sponsors.length == 0,
          text: 'Download terminated clients',
          query: {
            type: DownloadSponsorsType.TERMINATED,
            ...sort,
            q: debouncedSearch.query,
          },
          onClick: handleDownloadClick,
        }}
        label="Search in clients..."
        search={search}
        onChange={handleSearchChange}
      />
      <QueryResultUnconditional query={terminatedSponsors}>
        {(data) => {
          const filteredSponsors = filterSponsorsBenefits(data?.bookOfBusiness?.sponsors ?? [])

          return (
            <BookOfBusinessTable
              type="terminated"
              sponsors={filteredSponsors}
              emptyText={emptyText}
              totalItems={data?.bookOfBusiness?.totalRecords ?? 0}
              {...pagination}
              {...paginationCallbacks}
            />
          )
        }}
      </QueryResultUnconditional>
    </>
  )
}
