import type { UndefinedInitialDataOptions, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { hasNextPage } from '../../utils/pagination'
import { queryKeys } from '../../utils/queryKeys'
import { endpoints } from '../endpoints'

type UseGetSponsorParams = {
  query: Sponsor_ListRequestQueryParams
}
export type UseGetSponsorOptions = Pick<UseQueryOptions, 'enabled'> & {
  prefetchNextPage?: boolean
}

const listRequest = endpoints.Sponsor_List.getApiRequest()

// Sponsors list

export type UseGetSponsorsReturn = ReturnType<typeof useGetSponsors>

export function useGetSponsors(params: UseGetSponsorParams, options: UseGetSponsorOptions = {}) {
  function getQueryOptions(query: Sponsor_ListRequestQueryParams) {
    return {
      enabled: options.enabled,
      queryKey: queryKeys.getSponsors(query),
      queryFn: () => listRequest({ query }),
    } satisfies UndefinedInitialDataOptions
  }

  const results = useQuery(getQueryOptions(params.query))

  // Prefetch next page if enabled and there is a next page available
  const queryClient = useQueryClient()
  if (options.prefetchNextPage && canPrefetchNextPage(results, params.query)) {
    const currentPage = Number(params.query.page)
    queryClient.prefetchQuery(getQueryOptions({ ...params.query, page: String(currentPage + 1) }))
  }

  return results
}

export type UseGetActiveSponsorsReturn = ReturnType<typeof useGetActiveSponsors>

export function useGetActiveSponsors(params: UseGetSponsorParams, options: UseGetSponsorOptions = {}) {
  function getQueryOptions(query: Sponsor_ListRequestQueryParams) {
    query = { ...query, sponsorStatus: 'active' }
    return {
      enabled: options.enabled,
      queryKey: queryKeys.getActiveSponsors(query),
      queryFn: () => listRequest({ query }),
    } satisfies UndefinedInitialDataOptions
  }

  const results = useQuery(getQueryOptions(params.query))

  // Prefetch next page if enabled and there is a next page available
  const queryClient = useQueryClient()
  if (options.prefetchNextPage && canPrefetchNextPage(results, params.query)) {
    const currentPage = Number(params.query.page)
    queryClient.prefetchQuery(getQueryOptions({ ...params.query, page: String(currentPage + 1) }))
  }

  return results
}

/**
 * Represents the query parameters for the "GetRecentlyViewedSponsors" API request.
 * It extends the `Sponsor_ListRequestQueryParams` type and makes the `sponsorIds` property required.
 */
export type UseGetRecentlyViewedSponsorsQuery = Omit<Sponsor_ListRequestQueryParams, 'sponsorStatus' | 'favorite'> &
  Required<Pick<Sponsor_ListRequestQueryParams, 'sponsorIds'>>

export function useGetRecentlyViewedSponsors(
  params: { query: UseGetRecentlyViewedSponsorsQuery },
  options: UseGetSponsorOptions = {},
) {
  function getQueryOptions(query: UseGetRecentlyViewedSponsorsQuery) {
    return {
      enabled: options.enabled,
      queryKey: queryKeys.getRecentlyViewedSponsors(query),
      queryFn: () => listRequest({ query }),
    } satisfies UndefinedInitialDataOptions
  }

  const results = useQuery(getQueryOptions(params.query))

  // Prefetch next page if enabled and there is a next page available
  const queryClient = useQueryClient()
  if (options.prefetchNextPage && canPrefetchNextPage(results, params.query)) {
    const currentPage = Number(params.query.page)
    queryClient.prefetchQuery(getQueryOptions({ ...params.query, page: String(currentPage + 1) }))
  }

  return results
}

export function useGetFavoritedSponsors(params: UseGetSponsorParams, options: UseGetSponsorOptions = {}) {
  function getQueryOptions(query: Sponsor_ListRequestQueryParams) {
    query = { ...query, favorite: 'true' }
    return {
      enabled: options.enabled,
      queryKey: queryKeys.getFavoritedSponsors(query),
      queryFn: () => listRequest({ query }),
    } satisfies UndefinedInitialDataOptions
  }

  const results = useQuery(getQueryOptions(params.query))

  // Prefetch next page if enabled and there is a next page available
  const queryClient = useQueryClient()
  if (options.prefetchNextPage && canPrefetchNextPage(results, params.query)) {
    const currentPage = Number(params.query.page)
    queryClient.prefetchQuery(getQueryOptions({ ...params.query, page: String(currentPage + 1) }))
  }

  return results
}

export function useGetTerminatedSponsors(params: UseGetSponsorParams, options: UseGetSponsorOptions = {}) {
  function getQueryOptions(query: Sponsor_ListRequestQueryParams) {
    query = { ...query, sponsorStatus: 'terminated' }
    return {
      enabled: options.enabled,
      queryKey: queryKeys.getTerminatedSponsors(query),
      queryFn: () => listRequest({ query }),
    } satisfies UndefinedInitialDataOptions
  }

  const results = useQuery(getQueryOptions(params.query))

  // Prefetch next page if enabled and there is a next page available
  const queryClient = useQueryClient()
  if (options.prefetchNextPage && canPrefetchNextPage(results, params.query)) {
    const currentPage = Number(params.query.page)
    queryClient.prefetchQuery(getQueryOptions({ ...params.query, page: String(currentPage + 1) }))
  }

  return results
}

// Utils

/**
 * Checks if the next page can be prefetched based on the current query parameters and the total number of records.
 */
export function canPrefetchNextPage(
  results: UseQueryResult<Sponsor_ListResponse, Error>,
  query: Sponsor_ListRequestQueryParams,
) {
  const totalRecords = results.data?.bookOfBusiness?.totalRecords
  const currentPage = Number(query.page)
  const pageSize = Number(query.pageSize)
  return totalRecords !== undefined && hasNextPage(currentPage, pageSize, totalRecords)
}
